import React from "react";
import "./Stile.css"

export default function Enscape() {

    return (
        <>
            <div className="course-page">
                <h1 className="course-page-title">Enscape</h1>
                <div className="course-page-container">
                    <div className="course-page-card">
                        <div>
                            <div className="course-page-image-container">
                                <img className="course-page-image" src="/software/corsi/Enscape.png" alt="Enscape" />
                            </div>
                            <p>Enscape è uno strumento potente che consente di trasformare i progetti di progettazione e modellazione in esperienze immersive in realtà virtuale (VR) e realtà aumentata (AR). Utilizzando Enscape, i partecipanti alle tue lezioni private avranno l'opportunità di:

                                1. Vivere l'architettura e il design in modo coinvolgente: Enscape offre un'esperienza visiva realistica e coinvolgente, consentendo agli studenti di esplorare gli spazi di progetto come se fossero effettivamente presenti all'interno.

                                2. Ottimizzare la comprensione spaziale: Grazie alla visualizzazione 3D in tempo reale, gli studenti potranno comprendere meglio i layout e le dimensioni degli spazi progettati, migliorando la loro comprensione spaziale.

                                3. Valutare il design e prendere decisioni informate: Con Enscape, i partecipanti potranno valutare rapidamente il design e le scelte di materiali, l'illuminazione e altri aspetti, consentendo loro di prendere decisioni informate.

                                4. Esperienza di apprendimento interattiva: Lezioni private che utilizzano Enscape offrono un ambiente di apprendimento interattivo e coinvolgente, che aiuta a mantenere alta la motivazione degli studenti.

                                5. Creatività e innovazione: I partecipanti avranno la possibilità di sperimentare e innovare in modo creativo, permettendo loro di sviluppare idee e soluzioni originali per i progetti di design.

                                Unisciti alle mie lezioni private con Enscape e scopri come questa potente tecnologia può migliorare il tuo apprendimento nell'ambito dell'architettura e del design!</p>
                        </div>
                        <div>
                            <a href="/pdf/autocad-intermedio.pdf" download>
                                <button className="download-button">Scarica programma</button>
                            </a>
                            <p>per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="course-page-info">
                    <h2>A chi è rivolto?</h2>
                    <p>Il corso è rivolto a coloro che desiderano acquisire competenze immediatamente spendibili sul mercato del lavoro o per conoscenza personale, senza lasciare al caso la loro preparazione e affidandosi a un servizio formativo professionale e al passo coi tempi. Il corso è adatto a tutti coloro preferiscono avere la massima flessibilità e autonomia nella frequenza delle lezioni senza vincoli di luogo e di orario. Studenti e lavoratori.</p>
                    <h2>Come vengono svolte le lezioni?</h2>
                    <p>La formazione proposta per questo corso è online svolto direttamente dal tuo pc di casa o dal posto di lavoro in collegamento diretto. Questa modalità di lavoro ci è possibile grazie l’impiego  di un software che permette di lavorare in maniera interattiva.</p>
                    <h2>Le lezioni si possono registrare?</h2>
                    <p>Si certo le lezioni si possono registrare in modo tale da poterle renderle accessibili anche più avanti nel tempo.</p>
                    <h2>Cosa mi serve per iniziare?</h2>
                    <p>Per la partecipazione alle lezioni non è necessario avere nessun software installato, ti saranno forniti gratuitamente prima dell’inizio dei corsi.</p>
                    <h2>Com'è suddiviso il programma?</h2>
                    <p>Il programma è suddiviso in moduli, i quali vengono concordati in base agli obbiettivi di ogni corsista, quindi si creeranno dei percorsi totalmente personalizzati.</p>
                </div>
            </div>
        </>
    );

};