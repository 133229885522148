import React from "react";
import "./Stile.css"

export default function Archicad() {

    return (
        <>
            <div className="course-page">
                <h1 className="course-page-title">Archicad</h1>
                <div className="course-page-container">
                    <div className="course-page-card">
                        <div>
                            <div className="course-page-image-container">
                                <img className="course-page-image" src="/software/corsi/Archicad.jpg" alt="Archicad" />
                            </div>
                            <p>Archicad è un potente software di progettazione architettonica in 3D, ideale per architetti, designer e studenti di architettura. Con Archicad, puoi creare modelli virtuali dettagliati degli edifici, visualizzare progetti in modo realistico e migliorare la collaborazione con il team. Iscriviti alle mie lezioni private per imparare a utilizzare questo strumento eccezionale e aprire nuove opportunità nella tua carriera nell'ambito dell'architettura e del design!</p>
                        </div>
                        <div>
                            <a href="/pdf/autocad-intermedio.pdf" download>
                                <button className="download-button">Scarica programma</button>
                            </a>
                            <p>per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="course-page-info">
                    <h2>A chi è rivolto?</h2>
                    <p>Il corso è rivolto a coloro che desiderano acquisire competenze immediatamente spendibili sul mercato del lavoro o per conoscenza personale, senza lasciare al caso la loro preparazione e affidandosi a un servizio formativo professionale e al passo coi tempi. Il corso è adatto a tutti coloro preferiscono avere la massima flessibilità e autonomia nella frequenza delle lezioni senza vincoli di luogo e di orario. Studenti e lavoratori.</p>
                    <h2>Come vengono svolte le lezioni?</h2>
                    <p>La formazione proposta per questo corso è online svolto direttamente dal tuo pc di casa o dal posto di lavoro in collegamento diretto. Questa modalità di lavoro ci è possibile grazie l’impiego  di un software che permette di lavorare in maniera interattiva.</p>
                    <h2>Le lezioni si possono registrare?</h2>
                    <p>Si certo le lezioni si possono registrare in modo tale da poterle renderle accessibili anche più avanti nel tempo.</p>
                    <h2>Cosa mi serve per iniziare?</h2>
                    <p>Per la partecipazione alle lezioni non è necessario avere nessun software installato, ti saranno forniti gratuitamente prima dell’inizio dei corsi.</p>
                    <h2>Com'è suddiviso il programma?</h2>
                    <p>Il programma è suddiviso in moduli, i quali vengono concordati in base agli obbiettivi di ogni corsista, quindi si creeranno dei percorsi totalmente personalizzati.</p>
                </div>
            </div>
        </>
    );

};