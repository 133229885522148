import React from "react";
import "./Topbar.css";
import { Link, useLocation } from "react-router-dom"; // Import Link and useLocation from react-router-dom

export default function Topbar() {
    const location = useLocation(); // Get the current URL using useLocation()

    return (
        <div id="topbar">
            <Link id="title" to="/">
                <h3 id="site-name">Xhulian Hoxha</h3>
            </Link>
            <div id="topbar-links">
                <Link to="/" className={`navlink ${location.pathname === "/" ? "active" : ""}`}>Home</Link>
                <Link to="/chisono" className={`navlink ${location.pathname === "/chisono" ? "active" : ""}`}>Chi sono</Link>
                <Link to="/servizi" className={`navlink ${location.pathname === "/servizi" ? "active" : ""}`}>Servizi</Link>
                <Link to="/corsidiformazione" className={`navlink ${location.pathname === "/corsidiformazione" ? "active" : ""}`}>Corsi di formazione</Link>
            </div>
        </div>
    );
};
