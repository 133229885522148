import React, { useState, useEffect } from "react";
import "./Home.css"

export default function Home() {

    // Metodo per cambiare immagine
    const [currentIndex, setCurrentIndex] = useState(0);

    const images = [
        new URL("./immagini/1.jpeg", import.meta.url),
        new URL("./immagini/2.jpeg", import.meta.url),
        new URL("./immagini/3.jpeg", import.meta.url),
        new URL("./immagini/4.jpeg", import.meta.url)];

    useEffect(() => {

        const intervalId = setInterval(() => {
            setCurrentIndex(currentIndex => (currentIndex + 1) % images.length);
        }, 6000);

        return () => clearInterval(intervalId);

    }, [images.length]);

    const homeStyle = {
        backgroundImage: `url(${images[currentIndex]})`
    };

    return (
        <>
            <div id="home" style={homeStyle}>
                <div id="dialog">
                    <h2>Ciao mi chiamo Xhulian Hoxha</h2>
                    <p>sono un professionista appassionato che coniuga la precisione della geometria, la creatività dell'interior design e la conoscenza tecnica dell'ingegneria civile.</p>
                    <p>Sarei felice di averla come cliente e mi piacerebbe sapere cosa ne pensa dei servizi offerti. Per questo motivo, la invito a lasciare una recensione su Trustpilot. La ringrazio in anticipo per il suo contributo!</p>
                    <div id="trustpilot-container">
                        <a aria-label="Trustpilot" href="https://it.trustpilot.com/review/xhulianhoxha.com">
                            <img id="trustpilot-icon" alt="Trustpilot" src="/social/Trustpilot.jpg" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );

};