import React from "react";
import "./Corsi.css"
import Card from "../../componenti/card/Card";

export default function Corsi() {

    return (
        <div id="lessons-page">
            <h1 className="lessons-title">Corsi disponibili</h1>
            <div className="lessons-section" id="lessons-top-section">
                <Card link="/autocad" type="lesson-image" image="/software/Autocad.png" name={"AutoCad"} description={"Autodesk AutoCAD è il software leader di settore per la progettazione, il disegno, la modellazione."}
                    altro={
                        <>
                            <a href="/autocad">
                                <button className="redirect-button">Scopri di più</button>
                            </a>
                        </>
                    }
                />
                <Card type="lesson-image" image="/software/Archicad.png" name={"ArchiCad"} description={"Progetta, documenta e consegna progetti di tutte le dimensioni con il potente set di strumenti integrati di Archicad il software BIM più efficiente sul mercato."}
                    altro={
                        <>
                            <a href="/archicad">
                                <button className="redirect-button">Scopri di più</button>
                            </a>
                        </>
                    }
                />
                <Card type="lesson-image" image="/software/Sketchup.png" name={"SketchUp"} description={"Modello 3D, progettazione, architettura, modellazione, interfacce intuitive, strumenti di disegno, materiali, rendering."}
                    altro={
                        <>
                            <a href="/sketchup">
                                <button className="redirect-button">Scopri di più</button>
                            </a>
                        </>
                    }
                />
                <Card type="lesson-image" image="/software/Enscape.png" name={"Enscape"} description={"Crea facilmente render e animazioni di alta qualità per presentare il tuo progetto in modo realistico."}
                    altro={
                        <>
                            <a href="/enscape">
                                <button className="redirect-button">Scopri di più</button>
                            </a>
                        </>
                    }
                />
                <Card type="lesson-image" image="/software/Drone.png" name={"Rilievi"} description={"Esplora la fotogrammetria con drone: acquisisci dati aerei, crea modelli 3D e applica in vari settori professionali."}
                    altro={
                        <>
                            <a href="/rilievi">
                                <button className="redirect-button">Scopri di più</button>
                            </a>
                        </>
                    }
                />
            </div>
            <h1 className="lessons-title">Metodo di fruizione</h1>
            <div className="lessons-section" id="lessons-bottom-section">
                <div className="card">
                    <h2 className="card-title">One to One</h2>
                    <span class="material-symbols-outlined">
                        person
                    </span>
                    <p className="card-description">Gli unici corsi di tipo individuale dove puoi scegliere tu le date di svolgimento del corso e la modalità.</p>
                </div>
                <div className="card">
                    <h2 className="card-title">Fornitura Software</h2>
                    <span class="material-symbols-outlined">
                        terminal
                    </span>
                    <p className="card-description">Con l'accesso a uno dei corsi proposti, ti saranno forniti in maniera gratuita, tutti i software necessari all'apprendimento.</p>
                </div>
                <div className="card">
                    <h2 className="card-title">Assistenza continua</h2>
                    <span className="material-symbols-outlined">
                        call
                    </span>
                    <p className="card-description">Assistenza continua, fuori dagli orari di lezione, in caso tu ne abbia bisogno..</p>
                </div>
            </div>
        </div>
    );

};