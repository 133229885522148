import React from "react";
import "./Card.css"

export default function Card({ name, description, image, type, altro }) {

    return (
        <div className="card">
            <div>
                <div className="card-image-container">
                    <img className={type} src={image} alt={name} />
                </div>
                <h2 className="card-title">{name}</h2>
                <p className="card-description">{description}</p>
            </div>
            {altro}
        </div>
    );

};