import React from "react";
import "./Servizi.css"

import Card from "../../componenti/card/Card";

export default function Servizi() {

    return (
        <div id="services-page">
            <h1 id="services-title">Servizi</h1>
            <div id="card-container">
                <Card type="service-image" image="/servizi/project.jpg" name={"Progettazioni"} description={"Progettazioni edilizie su richiesta."}
                    altro={
                        <>
                            <p>Per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </>
                    }
                />
                <Card type="service-image" image="/servizi/3dmodel.jpg" name={"Modellazione 3D"} description={"Modellazioni poligonali."}
                    altro={
                        <>
                            <p>Per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </>
                    }
                />
            </div>
        </div>
    );

};