import React from "react";
import "./Stile.css"

export default function Autocad() {

    return (
        <>
            <div className="course-page">
                <h1 className="course-page-title">Autocad</h1>
                <div className="course-page-info">
                    <p>Autocad è uno strumento essenziale per la progettazione e la visualizzazione di disegni tecnici, poiché permette una rapida e precisa rappresentazione di idee e concezioni, migliorando la comunicazione e la collaborazione all’interno del processo di progettazione.</p>
                    <p>Iscrivendoti al corso di Autocad, potrai acquisire le competenze per creare progetti tecnici di alta qualità, migliorare la propria carriera e ampliare le opportunità di lavoro in un mercato in continuo crescita. Non perdere l’occasione di sviluppare le tue abilità e investire nel tuo futuro professionale.</p>
                </div>
                <div className="course-page-container">
                    <div className="course-page-card">
                        <div>
                            <div className="course-page-image-container">
                                <img className="course-page-image" src="/software/corsi/Autocad1.png" alt="Autocad" />
                            </div>
                            <h2>Corso intermedio</h2>
                            <p>Il corso intermedio di AutoCAD è progettato per fornire una solida conoscenza del software, approfondendo le funzionalità fondamentali per la creazione di disegni 2D e introducendo le funzionalità di base per la modellazione 3D, consentendo agli utenti di migliorare la loro produttività e di creare disegni più accurati e dettagliati.</p>
                        </div>
                        <div>
                            <a href="/pdf/autocad-intermedio.pdf" download>
                                <button className="download-button">Scarica programma</button>
                            </a>
                            <p>per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="course-page-card">
                        <div>
                            <div className="course-page-image-container">
                                <img className="course-page-image" src="/software/corsi/Autocad2.jpg" alt="Autocad" />
                            </div>
                            <h2>Corso avanzato</h2>
                            <p>Il corso avanzato di AutoCAD è progettato per approfondire le conoscenze e le abilità acquisite nel corso base, fornendo strumenti avanzati per la progettazione e la modellazione 2D e 3D, insieme ad una maggiore comprensione dei flussi di lavoro e delle funzionalità avanzate del software.</p>
                        </div>
                        <div>
                            <a href="/pdf/autocad-avanzato.pdf" download>
                                <button className="download-button">Scarica programma</button>
                            </a>
                            <p>per maggiori informazioni</p>
                            <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                                <div className="whatsapp-button-container">
                                    <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="course-page-info">
                    <h2>A chi è rivolto?</h2>
                    <p>Il corso è rivolto a coloro che desiderano acquisire competenze immediatamente spendibili sul mercato del lavoro o per conoscenza personale, senza lasciare al caso la loro preparazione e affidandosi a un servizio formativo professionale e al passo coi tempi. Il corso è adatto a tutti coloro preferiscono avere la massima flessibilità e autonomia nella frequenza delle lezioni senza vincoli di luogo e di orario. Studenti e lavoratori.</p>
                    <h2>Come vengono svolte le lezioni?</h2>
                    <p>La formazione proposta per questo corso è online svolto direttamente dal tuo pc di casa o dal posto di lavoro in collegamento diretto. Questa modalità di lavoro ci è possibile grazie l’impiego  di un software che permette di lavorare in maniera interattiva.</p>
                    <h2>Le lezioni si possono registrare?</h2>
                    <p>Si certo le lezioni si possono registrare in modo tale da poterle renderle accessibili anche più avanti nel tempo.</p>
                    <h2>Cosa mi serve per iniziare?</h2>
                    <p>Per la partecipazione alle lezioni non è necessario avere nessun software installato, ti saranno forniti gratuitamente prima dell’inizio dei corsi.</p>
                    <h2>Com'è suddiviso il programma?</h2>
                    <p>Il programma è suddiviso in moduli, i quali vengono concordati in base agli obbiettivi di ogni corsista, quindi si creeranno dei percorsi totalmente personalizzati.</p>
                </div>
            </div>
        </>
    );

};