import React from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation from react-router-dom
import "./Bottombar.css";

export default function Bottombar() {
    const location = useLocation(); // Get the current URL using useLocation()

    return (
        <>
            <div id="bottombar">
                <div id="bottombar-links">
                    <Link to="/" className={`bottomlink ${location.pathname === "/" ? "active" : ""}`}>
                        <span className="material-symbols-outlined">home</span>
                        <span className="icon-text">Home</span>
                    </Link>
                    <Link to="/chisono" className={`bottomlink ${location.pathname === "/chisono" ? "active" : ""}`}>
                        <span className="material-symbols-outlined">psychology_alt</span>
                        <span className="icon-text">Chi sono</span>
                    </Link>
                    <Link to="/servizi" className={`bottomlink ${location.pathname === "/servizi" ? "active" : ""}`}>
                        <span className="material-symbols-outlined">inventory_2</span>
                        <span className="icon-text">Servizi</span>
                    </Link>
                    <Link to="/corsidiformazione" className={`bottomlink ${location.pathname === "/corsidiformazione" ? "active" : ""}`}>
                        <span className="material-symbols-outlined">school</span>
                        <span className="icon-text">Corsi</span>
                    </Link>
                </div>
            </div>
        </>
    );
};