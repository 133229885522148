// App.js
import { useEffect } from 'react';

// Router
import { Route, Routes, useLocation } from "react-router-dom";

// File Stile CSS
import "./App.css";

// Pagine
import Home from "./pagine/home/Home";
import Demo from "./pagine/demo/Demo";
import Info from "./pagine/Info";
import Servizi from "./pagine/servizi/Servizi";
import Corsi from "./pagine/corsi/Corsi";

// Pagine Corsi
import Autocad from "./pagineCorsi/Autocad";
import Archicad from "./pagineCorsi/Archicad";
import Sketchup from "./pagineCorsi/Sketchup";
import Enscape from "./pagineCorsi/Enscape";
import Drone from "./pagineCorsi/Drone";

// Componenti
import Topbar from "./componenti/topbar/Topbar";
import Bottombar from "./componenti/bottombar/Bottombar";
import React from "react";

// Default
export default function App() {

  // ScrollToTop
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/chisono" element={<Demo />} />
        <Route path="/servizi" element={<Servizi />} />
        <Route path="/corsidiformazione" element={<Corsi />} />
        <Route path="/autocad" element={<Autocad />} />
        <Route path="/archicad" element={<Archicad />} />
        <Route path="/sketchup" element={<Sketchup />} />
        <Route path="/enscape" element={<Enscape />} />
        <Route path="/rilievi" element={<Drone />} />
      </Routes>
      <Topbar />
      <Bottombar />
    </>
  );
};