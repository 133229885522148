import React, { useEffect, useRef, useState } from 'react';
import "./Demo.css"

export default function Demo() {

    const containerRef = useRef(null);
    const textRef = useRef(null);
    const [marginTop, setMarginTop] = useState(0);

    const calculateMarginTop = () => {
        const container = containerRef.current;
        const text = textRef.current;

        // Calcola l'altezza del container e l'altezza del testo
        const containerHeight = container.clientHeight;
        const textHeight = text.clientHeight;

        // Imposta il margin-top del testo
        const calculatedMarginTop = ((containerHeight - textHeight) / 2);
        setMarginTop(calculatedMarginTop);
    };

    useEffect(() => {
        calculateMarginTop();
        window.addEventListener('resize', calculateMarginTop);

        return () => {
            window.removeEventListener('resize', calculateMarginTop);
        };
    }, []);

    return (
        <div id="info-page">
            <div ref={containerRef} id="info-container">
                <img src="Elon.png" alt="Xhulian" id="info-image" />
                <div ref={textRef} id="info-text">
                    <h2>Ciao mi chiamo Xhulian Hoxha</h2>
                    <p style={{ marginTop: `${marginTop}px` }}>sono un professionista appassionato che coniuga la precisione della geometria, la creatività dell'interior design e la conoscenza tecnica dell'ingegneria civile. La mia passione per l'arte e l'architettura mi ha spinto a intraprendere una carriera come geometra e interior designer, consentendomi di esprimere la mia creatività attraverso la trasformazione di spazi. La mia formazione come geometra mi ha fornito una solida base di conoscenze tecniche, dalla pianificazione degli spazi alla gestione dei materiali e delle risorse. Sono in grado di tradurre le idee dei miei clienti in progetti concreti, tenendo conto di aspetti pratici, estetici ed ergonomici. Parallelamente, sono uno studente di ingegneria civile, che mi offre una visione più approfondita degli aspetti strutturali e ingegneristici della mia professione. Sto costantemente affinando le mie competenze, imparando nuove metodologie e tecnologie per essere sempre all'avanguardia nel settore. La mia creatività si esprime attraverso l'interior design, in cui combino colori, texture, arredi e illuminazione per creare ambienti accoglienti e funzionali. Sono attento ai dettagli e alle esigenze dei miei clienti, cercando di superare le loro aspettative e di creare spazi che riflettano la loro personalità e stile di vita. Sono determinato e ambizioso nel perseguire il mio percorso professionale, costantemente alla ricerca di nuove sfide e opportunità di crescita. La mia passione per il design e l'architettura mi guida nel fornire soluzioni uniche e innovative, creando ambienti che lasciano un'impronta duratura sulla vita delle persone.</p>
                </div>
            </div>
            <h1>Portfolio</h1>
            <div className='info-section' id='portfolio'>
                <div className='image-home'>
                    <img src="/immagini/1.jpeg" alt="4" />
                </div>
                <div className='image-home'>
                    <img src="/immagini/2.jpeg" alt="4" />
                </div>
                <div className='image-home'>
                    <img src="/immagini/3.jpeg" alt="4" />
                </div>
                <div className='image-home'>
                    <img src="/immagini/4.jpeg" alt="4" />
                </div>
            </div>
            <h1>Contatti</h1>
            <div className="info-section">
                <div className="card" id="card-contacts">
                    <div>
                        <p>
                            <span class="material-symbols-outlined">
                                email
                            </span>
                        </p>
                        <a href="mailto:xhulian0703@gmail.com">
                            <button className="redirect-button">xhulian0703@gmail.com</button></a>
                    </div>
                    <div>
                        <p>
                            <span class="material-symbols-outlined">
                                phone
                            </span>
                        </p>
                        <a href="tel:+393401347387">
                            <button className="redirect-button">+393401347387</button>
                        </a>
                    </div>
                    <div>
                        <p>oppure</p>
                        <a aria-label="WhatsApp" href="https://wa.me/393401347387">
                            <div className="whatsapp-button-container">
                                <img className="whatsapp-button" alt="WhatsApp" src="/social/WhatsAppButton.png" />
                            </div>
                        </a>
                    </div>
                </div>
                <div className="card">
                    <p>
                        <span class="material-symbols-outlined">
                            location_on
                        </span>
                    </p>
                    <p>Via Gravina</p>
                    <p>70022</p>
                    <p>Altamura</p>
                    <p>Italia</p>
                </div>
                <div className="card">
                    <iframe title="Mappa" id="maps" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8295.909635190488!2d16.542486735429407!3d40.82814524954887!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1338789b75ad4991%3A0x9096cd438f3a239!2sVia%20Gravina%2C%2070022%20Altamura%20BA!5e0!3m2!1sit!2sit!4v1690581989438!5m2!1sit!2sit" loading="lazy"></iframe>
                </div>
            </div>
        </div >
    );

};